<template>
  <!-- 发放代金券 -->
  <div class="container">
    <a-page-header
      :title="title"
      :subTitle="subTitle"
      @back="() => $router.go(-1)"
    />

    <div class="main-content">
      <div class="content-item" v-if="info">
        <div class="content-item-header">
          <div class="title">代金券概况</div>
        </div>

        <div class="ul">
          <div class="li">
            <span>批次名称：</span>
            <span>{{ info.stock_name }}</span>
          </div>
          <div class="li">
            <span>已发券数量：</span>
            <span>{{ info.distributed_coupons }}</span>
          </div>
          <div class="li">
            <span>批次类型：</span>
            <span>{{ $stockTypes[info.stock_type] }}</span>
          </div>
        </div>

        <div class="ul">
          <div class="li">
            <span>可用开始时间：</span>
            <span>{{ info.available_begin_time }}</span>
          </div>
          <div class="li">
            <span>可用结束时间：</span>
            <span>{{ info.available_end_time }}</span>
          </div>
          <div class="li">
            <span>使用说明：</span>
            <a-tooltip
              placement="topLeft"
              :title="info.description"
              v-if="info.description"
            >
              <span>
                {{ info.description }}
              </span>
            </a-tooltip>
            <span v-else>-</span>
          </div>
        </div>

        <a-divider />

        <div class="radio-box">
          <div class="label">选择发券方式：</div>
          <a-radio-group v-model="radioValue">
            <a-radio v-for="r in issueTypes" :key="r.key" :value="r.key">
              {{ r.label }}
            </a-radio>
          </a-radio-group>
        </div>
      </div>

      <div class="content-item" v-if="radioValue === 1">
        <div class="content-item-header">
          <div class="title">指定注册用户（支持多人）</div>
        </div>

        <a-button
          class="user-plus-btn"
          type="dashed"
          icon="plus"
          @click="onPlusUser"
        >
          添加用户
        </a-button>

        <a-table
          :columns="columns"
          :data-source="dataSource"
          :loading="false"
          :pagination="false"
          :row-key="(record) => record.id"
        >
          <template slot="avatar" slot-scope="record">
            <img class="avatar" :src="record.avatar" />
          </template>
          <template slot="action" slot-scope="text, record, index">
            <a-popconfirm
              placement="bottomRight"
              ok-text="确定"
              cancel-text="取消"
              @confirm="onDelete(index)"
            >
              <template slot="title">
                <p>确定要删除吗？</p>
              </template>
              <a-button type="link">删除</a-button>
            </a-popconfirm>
          </template>
        </a-table>
      </div>

      <div class="issut-btn-box">
        <a-button
          class="issut-btn"
          type="primary"
          :disabled="dataSource.length === 0 && radioValue === 1"
          @click="onSubmitIssue"
        >
          确认发放
        </a-button>
      </div>
    </div>

    <a-modal
      title="添加用户"
      :width="376"
      :visible="isPlusUser"
      :footer="null"
      @cancel="onPlusUserClose"
    >
      <div class="modal-label">手机号</div>
      <a-input-search
        placeholder="请输入注册用户手机号"
        enter-button
        v-model="userSearchKey"
        @search="onSearchUser"
      />

      <div class="plus-user" v-if="plusUser">
        <div class="avatar">
          <img class="img" :src="plusUser.avatar" />
        </div>
        <div class="text-box">
          <div class="name">{{ plusUser.nickname }}</div>
          <div class="id">
            <span class="label">用户编号：</span>
            <span class="value">{{ plusUser.id }}</span>
          </div>
        </div>
      </div>

      <div class="btn-box" v-if="plusUser">
        <a-button @click="onPlusUserClose">
          取 消
        </a-button>
        <a-button @click="onPlusUserSubmit1">
          保存并继续
        </a-button>
        <a-button type="primary" @click="onPlusUserSubmit2">
          保存
        </a-button>
      </div>
    </a-modal>

    <a-modal :closable="false" :footer="null" :visible="isIssueModal">
      <div class="modal-body" v-if="!issueBtnLoading && !isIssueError">
        <div class="title-box">
          <a-icon type="exclamation-circle" theme="filled" />
          <span class="title">确认发放代金券</span>
        </div>
        <p class="body-text">
          总发放数量
          {{ userTotal }}
          张，如果用户领取失败，提示“暂时无法领取，建议用其他账号尝试”，说明该用户可能有刷单的风险，无法领券。
        </p>
      </div>
      <div class="modal-body" v-if="issueBtnLoading && !isIssueError">
        <div class="title-box">
          <a-icon type="exclamation-circle" theme="filled" />
          <span class="title">代金券发放中</span>
        </div>
        <p class="body-text">
          发放速度受用户数量影响，请不要关闭或刷新当前页面，若等待超过5分钟，请联系系统管理员。
        </p>
      </div>
      <div class="modal-body" v-if="!issueBtnLoading && isIssueError">
        <div class="title-box">
          <a-icon type="exclamation-circle" theme="filled" />
          <span class="title">代金券发放存在异常</span>
        </div>
        <p class="body-text">
          代金券发放存在异常：预计发放{{ userTotal }}张，成功
          {{ issueResult.successNum }}
          张，失败
          {{ issueResult.errorNum }}
          张。
        </p>
      </div>
      <div class="btn-box-2" v-if="isIssueError">
        <a-button @click="onIssueError">
          错误信息
        </a-button>
        <a-button type="primary" @click="onIssueClose">
          我知道了
        </a-button>
      </div>
      <div class="btn-box-2" v-else>
        <a-button @click="onIssueClose">
          取 消
        </a-button>
        <a-button
          type="primary"
          :loading="issueBtnLoading"
          @click="issueSubmit"
        >
          确 定
        </a-button>
      </div>
    </a-modal>

    <a-modal
      title="代金券发放 - 错误信息"
      :width="876"
      :visible="isIssueErrorModal"
      :footer="null"
      @cancel="onIssueErrorModalClose"
    >
      <a-table
        class="modal-table"
        :columns="columns2"
        :data-source="issueResult.errorList"
        :pagination="pagination"
        :row-key="(record) => record.userId"
        :scroll="{ x: 820 }"
        @change="handlePaginationChange"
      />
      <div class="modal-table-total">
        共计
        <span class="num">{{ pagination.total }}</span>
        条
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
let voucherId = null
export default {
  data() {
    return {
      info: null,
      issueTypes: [
        {
          key: 1,
          label: '指定注册用户'
        },
        {
          key: 2,
          label: '所有注册用户'
        }
      ],
      radioValue: 1,
      dataSource: [],
      columns: [
        {
          title: '序号',
          width: 70,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '用户编号',
          width: 100,
          dataIndex: 'id'
        },
        {
          title: '用户昵称',
          width: 150,
          dataIndex: 'nickname'
        },
        {
          title: '用户头像（微信）',
          scopedSlots: {
            customRender: 'avatar'
          }
        },
        {
          title: '操作',
          width: 100,
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      userSearchKey: '',
      isPlusUser: false,
      plusUser: null,
      isIssueModal: false,
      issueBtnLoading: false,
      isIssueError: false,
      isIssueErrorModal: false,
      dataSource2: [],
      columns2: [
        {
          title: '序号',
          width: 70,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '用户编号',
          width: 100,
          dataIndex: 'userId'
        },
        {
          title: '用户昵称',
          width: 150,
          dataIndex: 'nickname'
        },
        {
          title: '错误信息',
          dataIndex: 'msg'
        },
        {
          title: '错误代号',
          width: 100,
          dataIndex: 'errrCode'
        }
      ],
      pagination: {
        current: 1,
        total: 0,
        defaultPageSize: 3
      },
      issueResult: {
        errorList: []
      },
      userTotal: 0
    }
  },
  computed: {
    ...mapState(['$stockTypes'])
  },
  created() {
    this.title = this.$getPageTitle()
    const query = this.$route.query
    this.subTitle = query.name
    voucherId = query.id
    this.getVoucherInfo()
  },
  methods: {
    getVoucherInfo() {
      // 获取详情
      const data = {
        stocks: voucherId
      }
      this.$axios.getVoucherInfo(data).then((res) => {
        this.info = res.data.data
      })
    },
    onPlusUser() {
      // 显示添加用户弹窗
      this.isPlusUser = true
    },
    onPlusUserClose() {
      // 关闭添加用户弹窗
      this.isPlusUser = false
      this.plusUser = null
    },
    onSearchUser(key) {
      // 搜索用户
      const data = {
        keyword: key
      }
      this.$axios.getVoucherUser(data).then((res) => {
        this.plusUser = res.data.data.list[0]
      })
    },
    basePlusUser() {
      // 处理搜索结果
      const index = this.dataSource.findIndex((e) => e.id === this.plusUser.id)
      this.userSearchKey = ''
      if (index === -1) {
        this.dataSource.push(this.plusUser)
      } else {
        this.$message.error('该用户已添加过！')
      }
    },
    onPlusUserSubmit1() {
      // 保存并继续
      this.basePlusUser()
      this.plusUser = null
    },
    onPlusUserSubmit2() {
      // 保存
      this.basePlusUser()
      this.onPlusUserClose()
    },
    onDelete(i) {
      // 删除已添加的用户
      this.dataSource.splice(i, 1)
    },
    onSubmitIssue() {
      // 显示确认发放弹窗
      this.isIssueModal = true
      if (this.radioValue === 1) {
        this.userTotal = this.dataSource.length
      } else {
        this.getUser()
      }
    },
    onIssueClose() {
      // 关闭确认发放弹窗
      this.isIssueModal = false
    },
    issueSubmit() {
      // 确认发放
      this.issueBtnLoading = true
      const data = {
        stock: this.info.stock_id
      }
      if (this.dataSource.length && this.radioValue === 1) {
        data.userId = this.dataSource.map((e) => e.id)
      }
      this.$axios.voucherUserIssue(data).then((res) => {
        const d = res.data.data
        this.issueResult = d
        if (d.errorNum) {
          this.pagination.total = d.errorNum
          this.isIssueError = true
        } else {
          this.$message.success('操作成功')
          this.dataSource = []
        }
        this.issueBtnLoading = false
        this.getVoucherInfo()
      })
    },
    onIssueError() {
      // 显示发放错误信息
      this.isIssueErrorModal = true
    },
    onIssueErrorModalClose() {
      // 关闭发放错误信息弹窗
      this.isIssueErrorModal = false
    },
    handlePaginationChange(e) {
      // 发放错误信息分页
      this.pagination.current = e.current
    },
    getUser() {
      // 获取用户列表
      const data = {
        page: 1,
        limit: 1
      }
      this.$axios.getUsers(data).then((res) => {
        this.userTotal = res.data.data.count
      })
    }
  }
}
</script>

<style src="../../assets/css/info-page.css" scoped />
<style scoped>
.content-item-header {
  margin-bottom: 16px;
}

.radio-box {
  display: flex;
  align-items: center;
}

.radio-box .label {
  margin-right: 5px;
}

.user-plus-btn {
  width: 100%;
  height: 40px;
  margin-bottom: 12px;
}

.modal-label {
  margin-bottom: 8px;
}

.plus-user {
  height: 68px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  margin-top: 16px;
}

.plus-user .avatar {
  width: 52px;
  height: 52px;
  margin-right: 12px;
}

.plus-user .name {
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  font-weight: bold;
}

.plus-user .id {
  margin-top: 4px;
}

.plus-user .label,
.plus-user .value {
  font-size: 14px;
  line-height: 22px;
}

.plus-user .label {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
}

.plus-user .value {
  color: rgba(0, 0, 0, 0.65);
}

.btn-box {
  margin-top: 24px;
}

.btn-box .ant-btn:not(:last-child) {
  margin-right: 16px;
}

.issut-btn-box {
  display: flex;
  justify-content: flex-end;
}

.issut-btn {
  width: 88px;
}

.modal-body .title-box {
  display: flex;
  align-items: center;
}

.modal-body .anticon {
  color: #faad14;
  font-size: 24px;
  margin-right: 16px;
}

.modal-body .title {
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
}

.modal-body .body-text {
  line-height: 24px;
  color: rgba(0, 0, 0, 0.65);
  margin: 12px 0 24px 0;
  padding-left: 40px;
}

.btn-box-2 {
  display: flex;
  justify-content: flex-end;
}

.btn-box-2 .ant-btn:not(:last-child) {
  margin-right: 8px;
}

.modal-table >>> .ant-table-body {
  border: 1px solid #d9d9d9;
}

.modal-table-total {
  position: absolute;
  left: 32px;
  bottom: 40px;
  line-height: 32px;
}

.modal-table-total .num {
  color: #177ddc;
  font-weight: bold;
}
</style>
